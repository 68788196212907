import React from "react";

const Picture = ({src, alt, srcSet}) => {
    return (
        <picture>
            {srcSet && <source srcSet={srcSet} />}
            <img src={src} alt={alt} />
        </picture>
    )
}
export default Picture;