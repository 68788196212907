// import React from "react";

// const Video = ({src, alt, srcSet}) => {
//     return (
//         <video controls loop style={{ width: `100%` }}>
//         <source src={src} type="video/mp4" />
//         <track kind="description" srcLang="en" src={alt} />
//       </video>
//     )
// }
// export default Video;

import React from "react"
const Video = ({ videoSrcURL, videoTitle, ...props }) => (
  <div className="video">
    <iframe
      src={videoSrcURL}
      title={videoTitle}
      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
      frameBorder="0"
      webkitallowfullscreen="true"
      mozallowfullscreen="true"
      allowFullScreen
      height="100%" 
      width="100%"
    />
  </div>
)
export default Video