import React, {useState} from "react";
import {motion, AnimatePresence} from "framer-motion";
import {wrap} from "popmotion";
import './gallery.scss';
import Picture from "../Picture/Picture";

const variants = {
    enter: (direction) => {
        return {
            x: direction > 0 ? 1000 : -1000,
        };
    },
    center: {
        zIndex: 1,
        x: 0,
    },
    exit: (direction) => {
        return {
            zIndex: 0,
            x: direction < 0 ? 1000 : -1000,
        };
    }
};

const swipeConfidenceThreshold = 10000;
const swipePower = (offset, velocity) => {
    return Math.abs(offset) * velocity;
};

/**
 * Gallery component
 * @param images
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const Gallery = ({images}) => {
    const [[page, direction], setPage] = useState([0, 0]);
    const imageIndex = wrap(0, images.length, page);
    const paginate = (newDirection) => {
        setPage([page + newDirection, newDirection]);
    };

    return (
        <div className="gallery">
            <AnimatePresence initial={false} custom={direction}>
                <motion.div
                    className="picture-container"
                    key={page}
                    custom={direction}
                    variants={variants}
                    initial="enter"
                    animate="center"
                    exit="exit"
                    transition={{
                        x: {type: "spring", stiffness: 300, damping: 30},
                    }}
                    drag="x"
                    dragConstraints={{left: 0, right: 0}}
                    dragElastic={1}
                    onDragEnd={(e, {offset, velocity}) => {
                        const swipe = swipePower(offset.x, velocity.x);

                        if (swipe < -swipeConfidenceThreshold) {
                            paginate(1);
                        } else if (swipe > swipeConfidenceThreshold) {
                            paginate(-1);
                        }
                    }}
                >
                    <Picture
                        srcSet={images[imageIndex].srcSet}
                        src={images[imageIndex].src}
                        alt={images[imageIndex].alt}
                    />
                </motion.div>
            </AnimatePresence>
            <div className="next" onClick={() => paginate(1)}>
                {"‣"}
            </div>
            <div className="prev" onClick={() => paginate(-1)}>
                {"‣"}
            </div>
        </div>
    );
}
export default Gallery;