import React from "react";
import Layout from "../components/Layout/Layout";
import Text from "../components/Text/Text";
import { Col, Grid, Row } from 'react-flexbox-grid';
import ProjectList from "../components/ProjectList/ProjectList";
import Gallery from "../components/Gallery/Gallery";
import Picture from "../components/Picture/Picture";
import Video from "../components/Video/Video";

/**
 * Project page template
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const Project = ({ pageContext }) => {
    console.log({ pageContext });
    const { currentProjectData, selectedProjects, layoutData } = pageContext;
    const { headervideo, header, video, title, info, slug, gallery } = currentProjectData;

    return <Layout data={layoutData}>
        <section className="header">
        <Row>
                <Col lgOffset={2} lg={8} xs={12}>
                <Picture
                srcSet={header.srcSet}
                src={header.src}
                alt={header.alt}
            />
                            <h2>{title}</h2>
                            <Text>
                        <a target="_blank" rel="noreferrer" href={`${currentProjectData.url}`}>
                            &#x2192;view site
                        </a>
                    </Text>

                </Col>
            </Row>

        </section>
        <section className="project-details">
            <Row>
            <Col lgOffset={2} lg={8} xs={12}>
                    <Text>{info}</Text>

                    <small>Made in a design team at <a href="http://www.thonik.nl" target="_blank">thonik</a></small>
                </Col>
                
            </Row>
        </section>
        <section className="project-media">
            <Row>
                <Col lgOffset={2} lg={8} xs={12}>
                <Video
                videoSrcURL={video.srcSet}
                videoTitle={video.title}
            />
                </Col>
            </Row>
        </section>
        <section>
            <Row>
                <Col lgOffset={2} lg={8} xs={12}>
                                        <Gallery {...gallery} />
                </Col>
            </Row>
        </section>
        <section className="projects">
        <Row>
            <Col lgOffset={3} lg={6}>
            <Text><h3>more projects</h3></Text>
            </Col>
        </Row>
        {selectedProjects && <ProjectList projects={selectedProjects} />}
        </section>
    </Layout>
}

export default Project;